/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import types from './products.types';

export default {
  [types.FETCH_PRODUCTS_SUCCESS](
    state,
    { list, type, limit, page, total, source, search }
  ) {
    state.list = list;
    state.type = type;
    state.pagination = { limit, total, page };
    state.source = source;
    state.search = search;
    state.isLoading = false;
  },
  [types.FETCH_PRODUCTS_REQUEST](state, { silent = false }) {
    if (!silent) state.isLoading = true;
  },
  [types.FETCH_PRODUCTS_ERROR](state) {
    state.isLoading = false;
  },
  [types.FETCH_TEMPLATES_SUCCESS](state, { list }) {
    state.templates = list;
  },
  [types.FETCH_TEMPLATES_REQUEST]() {},
  [types.FETCH_TEMPLATES_ERROR]() {},
  [types.SWITCH_PRODUCT_SOURCE](state, { source }) {
    state.source = source;
  },
  [types.FETCH_PRODUCT_SOURCES_SUCCESS](state, { source, sources }) {
    state.source = source;
    state.sources = sources;
  },
  [types.REMOVE_PRODUCT_FROM_STATE](state, { productId }) {
    if (state.list.length > 0) {
      state.list = state.list.filter((p) => p.id !== productId);
    }
  },
  [types.EXPORT_PRODUCTS_REQUEST](state) {
    state.isExporting = true;
  },
  [types.EXPORT_PRODUCTS_SUCCESS](state, { products, type }) {
    state.lastExport = products;
    state.lastExportType = type;
    state.lastExportTimestamp = Date.now();
  },
  [types.EXPORT_PRODUCTS_ERROR](state) {
    state.isExporting = false;
  }
};
