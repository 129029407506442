/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as api from '../../api';
import { productTypes } from '../../const/product';
import filterObject from '../../helpers/filterObject';
import {
  errorToNotifications,
  successNotification,
  toWarningNotification
} from '../errors';
import { types } from './product.types';
import feathersClient from '../../feathers';
import { acceptLanguage } from '../../i18n';
import { getTenant, rootDispatch } from '../helper';
import integerMatches from '../../helpers/integerMatches';
import integerMismatches from '../../helpers/integerMismatches';
import { queryClient, queryClientBlob } from '../../api/queryClient';
import { commandClient, commandClientForm } from '../../api/commandClient';

const productService = () => feathersClient.service('2.0/products');

const exposableErrors = {
  InvalidPropertyName: {
    toNotification: toWarningNotification
  },
  DuplicateTechnicalName: {
    toNotification: toWarningNotification
  },
  FallbackReleaseNotInProduction: {
    toNotification: toWarningNotification
  }
};

const exposableErrorKeys = Object.keys(exposableErrors);

export const UNSAFE_NAME_ERROR = 'UNSAFE_NAME_ERROR';
export const DUPLICATE_NAME_ERROR = 'DUPLICATE_NAME_ERROR';

const fetchProductValidation = (dispatch, productId) =>
  dispatch(
    'sharedProperty/fetchProductValidation',
    { productId },
    {
      root: true
    }
  );

const validateTechnicalName = (technicalName) => {
  let characters = [];
  const unsafeUriRegEx = /[:/?@$&',;=\s]/g;
  if (unsafeUriRegEx.test(technicalName)) {
    characters = (technicalName || '')
      .match(unsafeUriRegEx)
      .reduce((result, char) => {
        if (char === ' ') {
          if (!result.includes('space')) {
            result.push('space');
          }
        } else if (!result.includes(char)) {
          result.push(char);
        }
        return result;
      }, []);
  }
  return characters.join(', ');
};

const postDispatch = async (dispatch, productId, tenant) => {
  dispatch('fetchProductWarnings', { tenant });
  rootDispatch(dispatch, 'productTests/fetch');
  fetchProductValidation(dispatch, productId);
};

const executeUpdateCommand = async (
  { commit, dispatch },
  tenant,
  productId,
  name,
  data,
  withPostDispatch = true,
  commitFetchSuccess = true
) => {
  if (!productId) throw new Error('Missing product id');
  if (!tenant) throw new Error('Missing tenant');
  if (!name || name.trim() === '') throw new Error('Missing command name');
  try {
    const product = await productService().update(productId, [{ name, data }], {
      query: { tenant },
      headers: {
        'accept-language': acceptLanguage()
      }
    });
    if (commitFetchSuccess) {
      commit(types.FETCH_PRODUCT_SUCCESS, {
        product
      });
    }
  } catch (error) {
    rootDispatch(
      dispatch,
      'notifications/addMap',
      errorToNotifications(error.error, exposableErrors, exposableErrorKeys)
    );
    commit(types.FETCH_PRODUCT_FAILURE);
    throw error;
  }
  if (withPostDispatch) {
    await postDispatch(dispatch, productId, tenant);
  }
};

const executeUpdateCommandWithoutPostDispatch = async (
  { commit, dispatch },
  tenant,
  productId,
  name,
  data
) =>
  executeUpdateCommand(
    { commit, dispatch },
    tenant,
    productId,
    name,
    data,
    false
  );

const executeCreateCommand = async (
  { commit, dispatch },
  tenant,
  name,
  data
) => {
  if (!tenant) throw new Error('Missing tenant');
  if (!name || name.trim() === '') throw new Error('Missing command name');
  let productId;
  try {
    const product = await productService().create([{ name, data }], {
      query: { tenant },
      headers: {
        'accept-language': acceptLanguage()
      }
    });
    productId = product.id;
    commit(types.FETCH_PRODUCT_SUCCESS, {
      product
    });
  } catch (error) {
    commit(types.FETCH_PRODUCT_FAILURE, error.response);
    throw error;
  }
  if (productId) await postDispatch(dispatch, productId, tenant);
};

const executeMarkAsDeletedCommand = async (commit, tenant, productId) => {
  if (!productId) throw new Error('Missing product id');
  if (!tenant) throw new Error('Missing tenant');
  try {
    await productService().remove(productId, {
      query: { tenant }
    });
  } catch (error) {
    commit(types.FETCH_PRODUCT_FAILURE, error.response);
    throw error;
  }
};

const fetchProductOnly = async (
  { commit, dispatch, state, rootState, getters },
  { productId, version = 'initial', silent = false },
  next
) => {
  commit(
    types.FETCH_PRODUCT_REQUEST,
    silent === false &&
      state.product &&
      integerMismatches(state.product.id, productId)
  );
  if (!silent) rootDispatch(dispatch, 'uiLoading/show');
  if (getters.copilotActivated) {
    dispatch('fetchCopilotStatus', productId);
  }
  try {
    const product = await productService().get(productId, {
      query: filterObject(
        {
          query: 'products.single',
          tenant: rootState.auth.tenant,
          version: rootState.productRelease.activeReleases[productId]
            ? rootState.productRelease.activeReleases[productId][1]
            : version
        },
        (v) => v !== undefined
      ),
      headers: {
        'accept-language': acceptLanguage()
      }
    });
    commit(types.FETCH_PRODUCT_SUCCESS, { product });
    if (next) {
      next(product);
    }
    if (!silent) rootDispatch(dispatch, 'uiLoading/hide');
  } catch (error) {
    commit(types.FETCH_PRODUCT_FAILURE, error.response);
    if (!silent) rootDispatch(dispatch, 'uiLoading/hide');
  }
};

export default {
  reset({ commit }) {
    commit(types.RESET);
  },
  setComplexityAllowed({ commit }, val) {
    commit(types.SET_COMPLEXITY_ALLOWED, val);
  },
  toggleSidebarVisibility({ state, commit }) {
    commit(types.SET_SIDEBAR_VISIBILITY, !state.sidebarIsVisible);
  },
  toggleInspectorVisibility({ state, commit }) {
    commit(types.SET_INSPECTOR_VISIBILITY, !state.inspectorIsVisible);
  },
  setProductConfigActiveTab({ commit }, { activeTab }) {
    commit(types.SET_PRODUCT_CONFIG_ACTIVE_TAB, activeTab);
  },
  toggleRuleDisplayType({ state, commit }) {
    commit(
      types.SET_RULE_DISPLAY_TYPE,
      state.ruleDisplayType === 'graph' ? 'table' : 'graph'
    );
  },
  toggleRequestWarnings({ state, commit }) {
    commit(types.SET_REQUEST_WARNINGS, !state.requestWarnings);
  },
  showInspector({ commit }, { show }) {
    commit(types.SET_INSPECTOR_VISIBILITY, show);
  },
  setTestCoverageMode({ commit }, { set }) {
    commit(types.SET_TEST_COVERAGE_MODE, set);
  },
  fetchProductWarnings({ commit, state }, { tenant }) {
    if (!state.product || !state.product.id) return;
    commit(types.FETCH_PRODUCT_WARNINGS_REQUEST);
    try {
      api
        .ide(
          state.product.id,
          tenant,
          state.requestWarnings,
          state.product.version.current
        )
        .then(({ data }) => commit(types.FETCH_PRODUCT_WARNINGS_SUCCESS, data));
    } catch (error) {
      commit(types.FETCH_PRODUCT_WARNINGS_ERROR, error.message);
    }
  },
  fetchProductComplexity({ commit, state }, { tenant }) {
    if (!state.product || !state.product.id) return;
    commit(types.FETCH_PRODUCT_COMPLEXITY_REQUEST);
    try {
      api
        .complexity(state.product.id, tenant)
        .then(({ data }) =>
          commit(types.FETCH_PRODUCT_COMPLEXITY_SUCCESS, data)
        );
    } catch (error) {
      commit(types.FETCH_PRODUCT_COMPLEXITY_ERROR, error.message);
    }
  },
  async refreshProduct(
    { commit, dispatch, state, rootState, getters },
    { productId, silent = false }
  ) {
    await fetchProductOnly(
      { commit, dispatch, state, rootState, getters },
      {
        productId: productId || state.product.id,
        version: state.product.version.current,
        silent
      },
      null
    );
    const { data: ide } = await api.ide(
      productId,
      rootState.auth.tenant,
      rootState.product.requestWarnings,
      state.product.version.current
    );
    commit(types.FETCH_PRODUCT_WARNINGS_SUCCESS, ide);
  },
  async fetchTests({ dispatch }) {
    rootDispatch(dispatch, 'productTests/fetch');
  },

  async fetchProduct(
    { commit, dispatch, state, rootState, getters },
    { productId, version, silent = false }
  ) {
    commit(types.SET_COPILOT_STATUS, undefined);
    await fetchProductOnly(
      { commit, dispatch, state, rootState, getters },
      { productId, version, silent },
      async (product) => {
        // Here productId can be the technical name
        const realProductId = Number.isNaN(productId)
          ? state.product.id
          : productId;
        await postDispatch(dispatch, realProductId, rootState.auth.tenant);
        rootDispatch(dispatch, 'definitions/reset');
        rootDispatch(dispatch, 'productRelease/setUserActiveProductRelease', {
          shadowProductId: product.id,
          productId: product.version.latest,
          version: product.version.current
        });
      }
    );
  },
  getRelatedProductsByQuestionnaire: async (
    { commit, rootState },
    { productId }
  ) => {
    commit(types.GET_RELATED_PRODUCT_REQUEST);
    try {
      const products = await queryClient(
        rootState.auth.tenant,
        'products',
        'getRelatedProductsByQuestionnaire',
        {
          id: productId,
          page: 1,
          count: 9999
        }
      );
      // filter out expired releases and sort the products by name so the releases of the same product are listed together
      const relatedProducts = products.data
        .filter((p) => {
          if (p.version.current === 'initial') return true;
          const release = p.version.list.find(
            (r) =>
              `${r.version.major}.${r.version.minor}.${r.version.patch}` ===
              p.version.current
          );
          return release.status !== 'EXPIRED';
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          if (a.currentVersion < b.currentVersion) {
            return -1;
          }
          if (a.currentVersion > b.currentVersion) {
            return 1;
          }
          return 0;
        });

      commit(types.GET_RELATED_PRODUCT_SUCCESS);
      return relatedProducts;
    } catch (error) {
      commit(types.GET_RELATED_PRODUCT_ERROR, error.response);
    }
    return [];
  },
  questionnaireSubstitution: async (
    { commit, rootState, state, dispatch },
    { data, rollback = false }
  ) => {
    const tenant = getTenant(rootState);
    const command = rollback
      ? 'replaceQuestionnaireRollback'
      : 'replaceQuestionnaire';
    try {
      return await executeUpdateCommand(
        { commit, dispatch },
        tenant,
        state.product.id,
        command,
        { tenant, ...data },
        false,
        false
      );
    } catch (error) {
      return { error };
    }
  },
  setGroup: async (
    { commit, dispatch, state, rootState },
    { productId, groupId }
  ) => {
    if (!groupId || integerMatches(state.product.tenant.id, groupId)) return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setGroup',
      {
        groupId: parseInt(groupId, 10)
      }
    );
  },
  setName: async (
    { commit, dispatch, state, rootState },
    { productId, name }
  ) => {
    if (!name || name.trim() === '' || state.product.name === name) return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setName',
      { name }
    );
  },
  resetTechnicalNameValidationError: ({ commit }) => {
    commit(types.SET_TECHNICAL_NAME_VALIDATION_SUCCESS);
  },
  setTechnicalName: async (
    { commit, dispatch, state, rootState },
    { productId, technicalName }
  ) => {
    if (state.product.technicalName === technicalName) return;

    const unsafeCharacters = validateTechnicalName(technicalName);
    if (unsafeCharacters) {
      commit(types.SET_TECHNICAL_NAME_VALIDATION_ERROR, {
        type: UNSAFE_NAME_ERROR,
        reason: {
          characters: unsafeCharacters
        }
      });
    } else {
      try {
        await executeUpdateCommand(
          { commit, dispatch },
          rootState.auth.tenant,
          productId || state.product.id,
          'setTechnicalName',
          {
            technicalName
          }
        );
      } catch (err) {
        commit(types.SET_TECHNICAL_NAME_VALIDATION_ERROR, {
          type: DUPLICATE_NAME_ERROR
        });
      }
    }
  },
  setAuthor: async (
    { commit, dispatch, state, rootState },
    { productId, author }
  ) => {
    if (!author || author.trim() === '' || state.product.author === author)
      return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setAuthor',
      { author }
    );
  },
  setComplexity: async (
    { commit, dispatch, state, rootState },
    { productId, complexity }
  ) => {
    if (!complexity) return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setComplexity',
      { complexity }
    );
  },
  setTeam: async (
    { commit, dispatch, state, rootState },
    { productId, team }
  ) => {
    if (!team || state.product.team.id === team) return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setTeam',
      { team }
    );
  },
  setSubtype: async (
    { commit, dispatch, state, rootState },
    { productId, subtype }
  ) => {
    if (!subtype || subtype.trim() === '' || state.product.subtype === subtype)
      return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setSubtype',
      { subtype }
    );
  },
  setDefaultLanguage: async (
    { commit, dispatch, state, rootState },
    { productId, defaultLanguage }
  ) => {
    if (
      !defaultLanguage ||
      defaultLanguage.trim() === '' ||
      state.product.defaultLanguage === defaultLanguage
    )
      return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'setDefaultLanguage',
      {
        defaultLanguage
      }
    );
  },
  attachQuestionnaire: async (
    { commit, dispatch, state, rootState },
    { productId, name, outputKey, questionnaireId, trigger }
  ) => {
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'attachQuestionnaire',
      { name, outputKey, questionnaireId, trigger }
    );
  },
  detachQuestionnaire: async (
    { commit, dispatch, state, rootState },
    { productId, id, trigger }
  ) => {
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'detachQuestionnaire',
      { id, trigger }
    );
  },
  configureQuestionnaire: async (
    { commit, dispatch, state, rootState },
    { productId, id, priority, outputKey, trigger, previousTrigger }
  ) => {
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || state.product.id,
      'configureQuestionnaire',
      { id, priority: priority || null, outputKey, trigger, previousTrigger }
    );
  },
  markAsProduct: async (
    { commit, dispatch, state, rootState },
    { productId }
  ) => {
    const { product } = state;
    if (product.type === productTypes.PRODUCT) return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || product.id,
      'markAsProduct',
      {}
    );
  },
  markAsTemplate: async (
    { commit, dispatch, state, rootState },
    { productId, templateTypeId }
  ) => {
    const { product } = state;
    if (
      product.type === productTypes.TEMPLATE &&
      product.template_type_id === templateTypeId
    )
      return;
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      productId || product.id,
      'markAsTemplate',
      { templateTypeId }
    );
  },
  markAsDeleted: async (
    { commit, dispatch, state, rootState },
    { productId }
  ) => {
    const { product } = state;
    await executeMarkAsDeletedCommand(
      commit,
      rootState.auth.tenant,
      productId || product.id
    );
    dispatch('products/removeProduct', { productId }, { root: true });
  },
  createProduct: async (
    { commit, dispatch, rootState },
    { name, author, teamId, insuranceType }
  ) => {
    await executeCreateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      'createProduct',
      {
        name,
        author,
        teamId,
        tenant: rootState.auth.tenant,
        insuranceType
      }
    );
  },
  createProductFromTemplate: async (
    { commit, dispatch, rootState },
    {
      name,
      author,
      teamId,
      template,
      businessLine,
      isCopy = false,
      release,
      insuranceType,
      type = productTypes.PRODUCT
    }
  ) => {
    const action = type === productTypes.TEMPLATE ? 'createTemplate' : 'createProductFromTemplate';
    await executeCreateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      action,
      {
        name,
        author,
        teamId,
        template,
        businessLine,
        tenant: rootState.auth.tenant,
        insuranceType,
        isCopy,
        release
      }
    );
  },
  uploadPdf: async ({ commit, rootState, dispatch }, { product, pdf }) => {
    commit(types.UPLOAD_PDF_REQUEST);
    try {
      const formData = new FormData();
      formData.append('file', pdf);
      formData.append('productName', product.name);
      await commandClientForm(
        rootState.auth.tenant,
        `copilot/product/${product.id}`,
        'uploadPdf',
        formData
      );
      commit(types.UPLOAD_PDF_SUCCESS);
      dispatch('fetchCopilotStatus', product.id);
      return product;
    } catch (error) {
      commit(types.UPLOAD_PDF_ERROR, error.response);
    }
    return undefined;
  },
  fetchCopilotStatus: async (
    { commit, getters, state, rootState, dispatch },
    productId
  ) => {
    if (!getters.copilotActivated) {
      return;
    }
    if (state.product && state.product.id !== Number(productId)) {
      return;
    }
    const { ok, data } = await queryClient(
      rootState.auth.tenant,
      `copilot/product/${productId}`,
      'ready'
    );
    if (!ok) {
      commit(types.SET_COPILOT_STATUS, undefined);
      return;
    }
    commit(types.SET_COPILOT_STATUS, data.message);
    if (!data.ready && data.message !== 'failed') {
      setTimeout(() => dispatch('fetchCopilotStatus', productId), 5000);
    }
    if (data.ready) {
      dispatch('fetchExtractedDimensions', productId);
    }
  },
  extractDefinitionsForDimension: async (
    { dispatch, getters, rootState, state },
    { dimensionName, productId }
  ) => {
    if (!getters.copilotActivated) {
      return;
    }
    if (state.product && state.product.id !== Number(productId)) {
      return;
    }
    await commandClient(
      rootState.auth.tenant,
      `copilot/product/${productId}`,
      'dimensions/definitions',
      { dimension: dimensionName }
    );
    dispatch('fetchProductExtractedDimensions', productId);
  },
  fetchProductExtractedDimensions: async (
    { state, getters, rootState, commit, dispatch },
    productId
  ) => {
    if (!getters.copilotActivated) {
      return;
    }
    if (state.product && state.product.id !== Number(productId)) {
      return;
    }
    const { ok, data } = await queryClient(
      rootState.auth.tenant,
      `copilot/product/${productId}`,
      'dimensions/definitions'
    );
    if (!ok) {
      commit(types.SET_PRODUCT_EXTRACTING_DIMENSIONS, undefined);
      return;
    }

    if (
      Object.values(data.dimensions).some((d) =>
        ['queued', 'started'].includes(d.status)
      )
    ) {
      setTimeout(
        () => dispatch('fetchProductExtractedDimensions', productId),
        5000
      );
    }

    commit(types.SET_PRODUCT_EXTRACTING_DIMENSIONS, data.dimensions);
  },
  downloadPdf: async ({ rootState }, productId) => {
    try {
      const pdf = await queryClientBlob(
        rootState.auth.tenant,
        `copilot/product/${productId}`,
        'downloadPdf'
      );
      return pdf;
    } catch (error) {
      return undefined;
    }
  },
  fetchExtractedDimensions: async ({ rootState, commit }, productId) => {
    const { data } = await queryClient(
      rootState.auth.tenant,
      `copilot/product/${productId}`,
      'definitions'
    );
    commit(types.SET_EXTRACTED_DIMENSIONS, data);
  },
  createQuestionnaireFromTemplate: async (
    { commit, dispatch, rootState },
    { name, author, teamId, template, businessLine, subtype, release }
  ) => {
    await executeCreateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      'createQuestionnaire',
      {
        name,
        author,
        teamId,
        template,
        businessLine,
        subtype,
        tenant: rootState.auth.tenant,
        release
      }
    );
  },
  createTemplate: async (
    { commit, dispatch, rootState },
    { name, author, teamId }
  ) => {
    await executeCreateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      'createTemplate',
      {
        name,
        author,
        teamId,
        tenant: rootState.auth.tenant
      }
    );
  },
  createQuestionnaire: async (
    { commit, dispatch, rootState },
    { name, author, teamId, subtype }
  ) => {
    await executeCreateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      'createQuestionnaire',
      {
        name,
        author,
        teamId,
        subtype,
        tenant: rootState.auth.tenant
      }
    );
  },
  toggleLock: async ({ commit, dispatch, state, rootState }) => {
    const { product } = state;
    if (
      [productTypes.SHADOW_PRODUCT, productTypes.SHADOW_QUESTIONNAIRE].includes(
        product.type
      )
    )
      return;
    const name = product.locked ? 'unlock' : 'lock';
    commit(types.TOGGLE_LOCK, {
      lock: name === 'lock'
    });
    try {
      await executeUpdateCommandWithoutPostDispatch(
        { commit, dispatch },
        rootState.auth.tenant,
        product.id,
        name
      );
      rootDispatch(
        dispatch,
        'productProperty/reloadProductProperties',
        state.product.id
      );
    } catch (err) {
      commit(types.TOGGLE_LOCK, {
        lock: !state.product.locked
      });
    }
  },
  attachProductMetadata: async (
    { commit, dispatch, state, rootState },
    { name, type }
  ) => {
    try {
      await executeUpdateCommand(
        { commit, dispatch },
        rootState.auth.tenant,
        state.product.id,
        'attachProductMetadata',
        {
          name,
          type,
          group: rootState.auth.tenant
        }
      );
      // todo this can be remove when we update the state directly
      rootDispatch(
        dispatch,
        'productProperty/fetchProductProperties',
        state.product.id
      );
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification(
          'PRODUCT_METADATA_ATTACHED',
          'Product Metadata created'
        )
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}
  },
  removeProductMetadata: async (
    { commit, dispatch, state, rootState },
    { name }
  ) => {
    try {
      await executeUpdateCommand(
        { commit, dispatch },
        rootState.auth.tenant,
        state.product.id,
        'removeProductMetadata',
        {
          name,
          group: rootState.auth.tenant
        }
      );
      dispatch('fetchProduct', { productId: state.product.id, silent: true });
      // todo this can be remove when we update the state directly
      rootDispatch(
        dispatch,
        'productProperty/fetchProductProperties',
        state.product.id
      );
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification(
          'PRODUCT_METADATA_REMOVED',
          'Product Metadata removed'
        )
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}
  },
  configureProductMetadata: async (
    { commit, dispatch, state, rootState },
    { color, name, previousName, type, value, withNotification = true }
  ) => {
    await executeUpdateCommand(
      { commit, dispatch },
      rootState.auth.tenant,
      state.product.id,
      'configureProductMetadata',
      {
        color,
        name,
        previousName,
        type,
        value,
        team: rootState.auth.tenant
      }
    );
    if (name.startsWith('_')) {
      // Protected metadata can change values in the product, so we need to reload here
      dispatch('refreshProduct', {
        productId: state.product.id,
        silent: true
      });
    }
    rootDispatch(
      dispatch,
      'productProperty/reloadProductProperties',
      state.product.id
    );
    if (withNotification) {
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification(
          'PRODUCT_METADATA_CONFIGURED',
          'Product Metadata updated'
        )
      );
    }
  },
  renameRule({ commit, dispatch, state }, {rules, oldName, newName}) {
    commit(types.RENAME_RULE, {rules, oldName, newName});
    try {
      api.renameRule(rules, oldName, newName);
    } catch(e) {
      dispatch('fetchProduct', { productId: state.product.id, tenant: state.tenant })
    }
  },
  updateRule({ commit }, { ruleId, rule }) {
    commit(types.UPDATE_RULE, rule);
    return api.updateRule(ruleId, rule);
  },
  deleteRule({ commit }, { ruleId }) {
    commit(types.DELETE_RULE, ruleId);
    return api.deleteRule(ruleId);
  }
};1
