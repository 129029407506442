/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { queryClient } from '../../api/queryClient';
import types from './products.types';
import filterObject from '../../helpers/filterObject';
import { productTypes } from '../../const/product';

export const findAll = async (options) => {
  const {
    tenant,
    count,
    page,
    source = 'all',
    search,
    sort,
    type,
    businessLine,
    insuranceType,
    as,
    team
  } = options;
  const params = filterObject(
    {
      query: 'products.all',
      count,
      page,
      source: type === productTypes.PRODUCT ? source : undefined,
      search: search && search.trim() !== '' ? search.trim() : undefined,
      sort,
      type,
      businessLine,
      insuranceType,
      as,
      userTeams: team ? (Array.isArray(team) ? team : [team]) : undefined
    },
    (v) => v !== undefined
  );
  return queryClient(tenant, 'products', 'all', params);
};

// todo check if it's used
export default {
  removeProduct({ commit }, { productId }) {
    commit(types.REMOVE_PRODUCT_FROM_STATE, {
      productId
    });
  },
  async exportProducts({ commit, rootState }, { type, source }) {
    commit(types.EXPORT_PRODUCTS_REQUEST);
    try {
      const products = await queryClient(
        rootState.auth.tenant,
        'products',
        'basicReporting',
        filterObject(
          {
            type,
            source: source || undefined
          },
          (v) => v !== undefined
        )
      );

      commit(types.EXPORT_PRODUCTS_SUCCESS, {
        products,
        type
      });
    } catch (error) {
      commit(types.EXPORT_PRODUCTS_ERROR);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  async fetchProductSources({ commit, rootState }) {
    try {
      const sources = await queryClient(
        rootState.auth.tenant,
        'products',
        'sources',
        {}
      );

      commit(types.FETCH_PRODUCT_SOURCES_SUCCESS, {
        source: sources[0],
        sources
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  async switchProductSource({ commit }, { source }) {
    commit(types.SWITCH_PRODUCT_SOURCE, { source });
  },
  async fetchAllTemplates({ commit, rootState }) {
    commit(types.FETCH_TEMPLATES_REQUEST);
    try {
      const { data } = await findAll({
        tenant: rootState.auth.tenant,
        type: productTypes.TEMPLATE,
        as: 'simple-search'
      });
      commit(types.FETCH_TEMPLATES_SUCCESS, {
        list: data
      });
    } catch (error) {
      commit(types.FETCH_TEMPLATES_ERROR);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  async fetchAllProducts(
    { commit, rootState },
    {
      type,
      silent = false,
      count,
      page,
      source,
      search,
      sort,
      as,
      team,
      insuranceType
    }
  ) {
    commit(types.FETCH_PRODUCTS_REQUEST, { silent });
    try {
      const { data, limit, total } = await findAll({
        tenant: rootState.auth.tenant,
        count,
        page,
        source,
        search,
        sort,
        type,
        as,
        team,
        insuranceType
      });
      commit(types.FETCH_PRODUCTS_SUCCESS, {
        list: data,
        type,
        limit,
        page,
        total,
        source,
        search
      });
    } catch (error) {
      commit(types.FETCH_PRODUCTS_ERROR);
      throw error;
    }
  }
};
