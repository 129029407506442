/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default {
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_REQUEST: 'FETCH_PRODUCTS_REQUEST',
  FETCH_PRODUCTS_ERROR: 'FETCH_PRODUCTS_ERROR',
  FETCH_TEMPLATES_SUCCESS: 'FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_REQUEST: 'FETCH_TEMPLATES_REQUEST',
  FETCH_TEMPLATES_ERROR: 'FETCH_TEMPLATES_ERROR',
  FETCH_PRODUCT_SOURCES_SUCCESS: 'FETCH_PRODUCT_SOURCES_SUCCESS',
  SWITCH_PRODUCT_SOURCE: 'SWITCH_PRODUCT_SOURCE',
  REMOVE_PRODUCT_FROM_STATE: 'REMOVE_PRODUCT_FROM_STATE',
  EXPORT_PRODUCTS_SUCCESS: 'EXPORT_PRODUCTS_SUCCESS',
  EXPORT_PRODUCTS_REQUEST: 'EXPORT_PRODUCTS_REQUEST',
  EXPORT_PRODUCTS_ERROR: 'EXPORT_PRODUCTS_ERROR'
};
