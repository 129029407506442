<template>
  <div class="d-flex flex-grow-1">
    <product-export-manager v-if="product" :product-id="product.version.latest" :visible="showProductExportManager"
      :current-export-properties="exportProperties" @close="(success) => closeExportWizard()"></product-export-manager>
    <nav v-selector class="d-flex flex-column flex-grow-1 not-selectable">
      <HeaderLogo :home-url="homeUrl" class="flex-shrink-0 p-3" />

      <nav class="nav-bar flex-shrink-0">
        <router-link :to="{ name: 'products-index' }" active-class="" exact-active-class=""
          :class="activeClasses['products']" data-cy="products-tab" class="text-dark px-4 py-2">
          <b-iconstack>
            <b-icon icon="files" />
            <b-icon rotate="-90" icon="diagram3-fill" scale="0.5" />
          </b-iconstack>
          <span>
            {{ $t('common.insurance-models') }}
          </span>
        </router-link>
        <router-link :to="{ name: 'templates-index' }" active-class="" exact-active-class=""
          :class="activeClasses['templates']" data-cy="templates-tab" class="text-dark px-4 py-2">
          <b-icon icon="book" />
          <span>
            {{ $t('common.model-templates') }}
          </span>
        </router-link>
        <router-link :to="{ name: 'questionnaires-index' }" active-class="" exact-active-class=""
          :class="activeClasses['questionnaires']" data-cy="questionnaires-tab" class="text-dark px-4 py-2">
          <b-iconstack>
            <b-icon icon="files" />
            <b-icon icon="question" scale="0.75" />
          </b-iconstack>
          <span>
            {{ $t('common.questionnaires') }}
          </span>
        </router-link>
        <div class="px-3 py-1 mt-3 shared-toggle" v-b-toggle.shared-collapse>
          <small>
            <b-icon icon="chevron-down" class="when-closed" />
            <b-icon icon="chevron-up" class="when-open" />
            <span>
              {{ $t('common.properties') }}
            </span>
          </small>
        </div>
        <b-collapse visible id="shared-collapse">
          <router-link :to="{ name: 'definition-lists-index' }" :active-class="activeClass"
            :exact-active-class="exactActiveClass" data-cy="definition-lists-tab" class="text-dark px-4 py-2">
            <b-icon icon="card-list" />
            <span>
              {{ $t('common.definitions') }}
            </span>
          </router-link>
          <router-link :to="{ name: 'properties-index', params: { type: 'dimension' } }" :active-class="activeClass"
            :exact-active-class="exactActiveClass" data-cy="shared-dimensions-tab" class="text-dark px-4 py-2">
            <b-icon icon="layers" />
            <span>
              {{ $t('product.dimensions') }}
            </span>
          </router-link>
          <router-link :to="{ name: 'properties-index', params: { type: 'input' } }" :active-class="activeClass"
            :exact-active-class="exactActiveClass" data-cy="shared-inputs-tab" class="text-dark px-4 py-2">
            <b-icon icon="input-cursor-text" />
            <span>
              {{ $t('product.inputs') }}
            </span>
          </router-link>
          <router-link :to="{ name: 'properties-index', params: { type: 'computed' } }" :active-class="activeClass"
            :exact-active-class="exactActiveClass" data-cy="shared-computeds-tab" class="text-dark px-4 py-2">
            <b-icon icon="calculator" />
            <span>
              {{ $t('product.computeds') }}
            </span>
          </router-link>
          <router-link :to="{ name: 'properties-index', params: { type: 'output' } }" :active-class="activeClass"
            :exact-active-class="exactActiveClass" data-cy="shared-outputs-tab" class="text-dark px-4 py-2">
            <b-icon icon="box-arrow-right" />
            <span>
              {{ $t('product.outputs') }}
            </span>
          </router-link>
          <router-link :to="{ name: 'properties-index', params: { type: 'unit' } }" :active-class="activeClass"
            :exact-active-class="exactActiveClass" data-cy="shared-units-tab" class="text-dark px-4 py-2">
            <b-icon icon="rulers" />
            <span>
              {{ $t('product.units') }}
            </span>
          </router-link>
        </b-collapse>
      </nav>

      <div class="flex-grow-1" />

      <div v-if="isLoggedIn" class="flex-shrink-0 px-3 pt-3 pb-2 menu-user-section">
        <b-dropdown block dropup boundary="window" no-caret variant="outline-light" class="tenant-selector">
          <div slot="button-content" class="text-left d-flex flex-row flex-nowrap align-items-center text-dark">
            <div class="flex-grow-1">
              <span class="text-uppercase">{{ selectedTenant }}</span><br />
              <span>{{ currentRole }}</span>
            </div>
            <b-icon class="flex-shrink-0" icon="chevron-expand" scale="0.75" />
          </div>
          <b-dropdown-item href="#" v-for="team in getTenantList()" :key="team.slug"
            @click="selectedTenant = team.slug">
            <div class="d-flex flex-row flex-nowrap">
              <div class="flex-grow-1 text-uppercase">{{ team.slug }}</div><small class="flex-shrink-0 text-muted">
                {{ team.role }}
              </small>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown block dropup boundary="window" variant="light" no-caret class="profile-menu mt-2">
          <div slot="button-content"
            class="text-left flex-grow-1 d-flex flex-row flex-nowrap align-items-center text-dark">
            <b-icon class="flex-shrink-0" icon="person-circle" scale="0.75" />
            <span class="text-uppercase user-email flex-grow-1 mx-2 text-truncate">{{ user.email }}</span>
            <b-icon class="flex-shrink-0" icon="gear" scale="0.75" />
            <b-icon class="flex-shrink-0" icon="chevron-right" scale="0.5" />
          </div>
          <b-dropdown-item v-if="isOwner()" :to="{ name: 'roles' }">
            {{ $t('team.menu') }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'profile' }">
            {{ $t('auth.my_account') }}
          </b-dropdown-item>
          <b-dropdown-form>
            <div class="d-flexflex-row flex-nowrap align-items-center">
              <div class="flex-grow-1">
                {{ $t('language') }}
              </div>
              <LanguageSwitcher class="ui_select_lang flex-shrink-0" />
            </div>
          </b-dropdown-form>
          <b-dropdown-divider v-if="(isSuperAdmin() || isImpersonated)" />
          <b-dropdown-item v-if="isSuperAdmin()" :to="{ name: 'maintenance' }">
            {{ $t('auth.maintenance') }}
          </b-dropdown-item>
          <b-dropdown-form>
            <ImpersonateDropdown v-if="(isSuperAdmin() || isImpersonated)" />
          </b-dropdown-form>
          <b-dropdown-divider />
          <b-dropdown-item-button @click="logOutAndRedirect">
            {{ $t('user.logout') }}
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
    </nav>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapState, mapActions } from 'vuex';
import { Message } from 'element-ui';
import LanguageSwitcher from './LanguageSwitcher.vue';
import HeaderLogo from './HeaderLogo.vue';
import { name as appName } from '../config';
import ProductExportManager from './ProductExportManager.vue';
import GtmMixin from './GtmMixin';
import ImpersonateDropdown from './ImpersonateDropdown.vue';

export default {
  name: 'HeaderComponent',
  components: {
    HeaderLogo,
    LanguageSwitcher,
    ProductExportManager,
    ImpersonateDropdown
  },
  mixins: [GtmMixin],
  data: () => ({
    appName,
    announcements: [],
    showProductExportManager: false,
    selectedTenant: null,
    isProductReadOnly: false,
    displayMaintenanceMessage: false,
  }),
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
      'isAdmin',
      'isOwner',
      'isGuest',
      'isSuperAdmin',
      'isImpersonated'
    ]),
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapState('teams', ['teams', 'tenants']),
    ...mapState('maintenance', ['settings']),
    ...mapState('product', ['product', 'isLoading']),
    ...mapState('users', ['roles']),
    homeUrl() {
      return this.tenant ? `/t/${this.tenant}/` : '';
    },
    isReadOnlyMode() {
      return (
        this.isReadOnly ||
        (this.isGuest(this.product && this.product.team.slug) &&
          !this.isOwner())
      );
    },
    showProductName() {
      return (
        this.product &&
        this.$route.name &&
        this.$route.name.startsWith('product-')
      );
    },
    superAdminTitle() {
      return (this.roles && this.roles.find((r) => r.id === 1).label) || [];
    },
    selectedTeam() {
      return (
        this.teams &&
        this.teams.find(
          (t) => this.selectedTenant && t.tenant === this.selectedTenant
        )
      );
    },
    exportProperties() {
      return {
        name: this.product.name,
        type: this.product.type
      };
    },
    isProd() {
      return process.env.NODE_ENV === 'production';
    },
    currentRole() {
      return this.getRoleLabel(this.tenant) || 'Loading...'
    },
    activeClass() {
      return !["/", "/roles", "/profile", "/maintenance"].includes(this.$route.fullPath) ? 'router-link-active' : '';
    },
    exactActiveClass() {
      return !["/", "/roles", "/profile", "/maintenance"].includes(this.$route.fullPath) ? 'router-link-exact-active' : '';
    },
    selectedType() {
      return this.$route.meta.type;
    },
    activeClasses() {
      return {
        products: this.$route.fullPath !== "/" && (this.$route.fullPath.includes('/products') && this.selectedType === 'PRODUCT' || (this.$route.fullPath.includes('/products') && this.product && !['TEMPLATE', 'QUESTIONNAIRE', 'SHADOW_QUESTIONNAIRE'].includes(this.product.type))) ? 'router-link-active' : '',
        templates: this.$route.fullPath !== "/" && (this.$route.fullPath.includes('/templates') && this.selectedType === 'TEMPLATE' || (this.$route.fullPath.includes('/products') && this.product && !['PRODUCT', 'QUESTIONNAIRE', 'SHADOW_QUESTIONNAIRE', 'SHADOW_PRODUCT'].includes(this.product.type))) ? 'router-link-active' : '',
        questionnaires: this.$route.fullPath !== "/" && (this.$route.fullPath.includes('/questionnaires') && this.selectedType === 'QUESTIONNAIRE' || (this.$route.fullPath.includes('/products') && this.product && !['TEMPLATE', 'PRODUCT', 'SHADOW_PRODUCT'].includes(this.product.type))) ? 'router-link-active' : '',
      }
    }
  },
  watch: {
    user: {
      async handler() {
        if (this.user) {
          await this.getSettings();
          if (!this.roles) await this.getRoles();
          if (this.isAdmin()) {
            if (!this.tenants) await this.loadTenants();
          }
          if (!this.teams) await this.loadTeams();
        }
      },
      immediate: true
    },
    tenant: {
      async handler() {
        this.selectedTenant = this.tenant;
        if (this.tenant) this.setGtm(this.tenant);
      },
      immediate: true
    },
    selectedTenant() {
      this.setTenant({ tenant: this.selectedTenant, forceReload: true });
    }
  },
  methods: {
    ...mapActions('auth', ['logOut', 'setTenant']),
    ...mapActions('teams', ['loadTenants', 'loadTeams']),
    ...mapActions('users', ['getRoles']),
    ...mapActions('maintenance', ['getSettings']),
    closeExportWizard() {
      this.showProductExportManager = false;
    },
    exportProduct() {
      this.showProductExportManager = true;
    },
    async logOutAndRedirect() {
      await this.logOut();
      Message.info('You have been logged out');
    },
    getTenantList() {
      const tenants = (this.isSuperAdmin()
        ? this.tenants && [...this.tenants]
        : this.user.teams && [
          ...this.user.teams.filter((t) => t.slug === t.tenant)
        ]) || [];
      tenants.sort((a, b) => {
        if (a.slug < b.slug) return -1;
        if (a.slug > b.slug) return 1;
        return 0;
      });
      return tenants.map(t => {
        return {
          ...t,
          role: this.getRoleLabel(t.slug)
        }
      });
    },
    tenantAndRole(tenantId) {
      if (this.isSuperAdmin()) {
        return this.tenants.find((g) => g.id === tenantId).label;
      }
      const team =
        this.user.teams && this.user.teams.find((g) => g.id === tenantId);
      const role =
        this.roles &&
        this.roles.find((r) => r.id === team.UserTeam.team_role_id);
      return `${(team && team.label) || ''} (${(role && role.label) || ''})`;
    },
    getRoleLabel(tenant) {
      if (this.roles) {
        const teams = this.user.teams;
        const currentTeam = teams.find(t => t.slug === tenant);
        if (currentTeam) {
          return this.roles.find(role => role.id === currentTeam.UserTeam.team_role_id).label
        }
        if (teams.some(team => team.UserTeam.team_role_id === 1 || team.UserTeam.tenant_role_id === 1)) {
          return this.roles.find(role => role.id === 1).label
        }
      }
      return undefined
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

header {
  position: relative;
  z-index: 2;
  min-height: 34px;
  background: #f0f0f0;
  border-bottom: 1px solid rgba(43, 48, 52, 0.1);
  text-transform: uppercase;
  font-family: $headings-font-family;

  .el-dropdown {
    font-size: $font-size-base;
  }

  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-setting {
    color: $color-axa-blue;
  }

  .select-tenant {
    color: #343c3d;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: #ffffff00 url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e') right 20px center/8px 10px no-repeat;
    text-transform: uppercase;
    max-width: 320px;
    text-overflow: ellipsis;
    max-height: 32px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  button .icon {
    height: 14px;
    width: 14px;
  }

  .app-name {
    color: $color-axa-blue;
  }

  .is-circle {
    width: 38px;
    height: 38px;
  }

  .logo {
    width: 50px;
    height: 50px;
    // Align logo to top
    display: inline-block;
    position: relative;
    top: -4px;
  }

  .helpIcon {
    font-size: 2em;
    margin-left: 0.8em;
  }

  .cartridge {
    margin-left: 1em;
    margin-right: 1em;
    padding: 0px 10px;
    background-color: gray;
    color: white;
    border-radius: 1em;
  }

  .top_separator {
    font-size: 0.8em;
    display: inline-block;
    padding-top: 0.2em;
    color: #343c3d;
  }
}

.ProductName {
  display: flex;
  align-items: center;

  >span {
    margin-right: 0.2em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ProductVersion {
  margin-left: 0.4rem;
}

.ProductRootline {
  display: flex;
  align-items: center;

  &__Breadcrumb {
    display: flex;
    align-items: center;

    >span {
      &:after {
        content: '-';
        margin: 0 0.2rem;
      }

      &:last-child {
        &:after {
          content: '';
          margin-right: 0;
        }
      }
    }
  }
}

.maintenance {
  width: 100%;
  padding: 8px;
  text-align: center;
}

.modeON {
  background: red;
  color: white;
}

.modeOFF {
  background: #53dcea;
  color: black;
}

.temporarilyHidden {
  width: 60px;

  a {
    display: none !important;
  }
}

.gap-1 {
  gap: 1em;
}

.menu-user-section {
  border-top: solid 1px $color-gray--light;
}


nav.nav-bar a {
  text-transform: uppercase;
  gap: 8px;
  font-size: 14px;
  letter-spacing: 1px;
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  position: relative;

  &.router-link-active {
    color: $color-axa !important;
    opacity: 1;
    font-weight: 600;

    >span {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 16px;
        right: 16px;
        bottom: 0;
        background-color: $color-axa-blue;
        opacity: 0.1;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 3px;
      background-color: #d24723;
    }
  }
}

.shared-toggle {
  color: $color-gray--dark;
}

.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}

.tenant-selector {
  background-color: $color-gray--lighter;

  span {
    font-size: 12px;
  }
}

.user-email {
  font-size: 12px;
}
</style>

<style>
.profile-menu .dropdown-menu,
.tenant-selector .dropdown-menu {
  position: fixed !important;
  max-height: 600px;
  overflow-y: auto;
}
</style>
